import moment from 'moment'

export const formatDate = (date, opts = null) => {
  if (!date) return null
  const options = opts || { year: 'numeric', month: 'numeric', day: 'numeric' }
  const result = Object.prototype.hasOwnProperty.call(date, 'toDate') ? date.toDate() : new Date(date)
  return result.toLocaleDateString("ru-RU", options)
}

export const formatDateTime = (date, opts = null) => {
  if (!date) return null
  const options = opts || { hour: '2-digit', minute: '2-digit' }
  const result = date.toDate()
  return result.toLocaleTimeString("ru-RU", options)
}

export const groupBy = (arr, prop, dateFirst = false ) => {
  const groupedDays = arr.reduce((acc, el) => {
      const dateProp = new Date(el[1][prop].toDate())
      const messageDay = moment(dateProp).format('YYYY-MM-DD')
      let res
      if (acc[messageDay]) {
          const concated = acc[messageDay].concat([el])
          res = { ...acc, [messageDay]: concated }
      } else {
          res = { ...acc, [messageDay]: [el] }
      }
      
      return res
  }, {})

  const days = groupedDays
  const sortedDays = Object.keys(days).sort(
      (x, y) => moment(y, 'YYYY-MM-DD').unix() - moment(x, 'YYYY-MM-DD').unix()
  )

  const items = sortedDays.reduce((acc, date) => {
      const sortedMessages = days[date].sort(
      (x, y) => new Date(y[1][prop]) - new Date(x[1][prop])
      );
      const dayItem = { type: 'day', date: new Date(date), id: date }
      const result = !dateFirst ? [...sortedMessages, dayItem] : [dayItem, ...sortedMessages]
      return acc.concat(result)
  }, []);
  return items
}

export const getDeepKeys = (obj, exclude = []) => {
  let keys = []

  if (typeof obj === 'object' && !Array.isArray(obj)) {
    for(let key in obj) {
      if (exclude.includes(key)) continue
      keys.push(key)
      if(typeof obj[key] === "object") {
        var subkeys = getDeepKeys(obj[key])
        keys = keys.concat(subkeys.map(subkey => {
            return key + "." + subkey
        }))
      }
    }
  }
  return keys
}

export const getValueFromObjectByKey = (obj, key) => {
  let parts = key.split( "." ),
    length = parts.length,
    i,
    property = obj || this

  for ( i = 0; i < length; i++ ) {
    property = property[parts[i]]
  }

  return property
}