<template>
  <div 
    class="profile"
  >
    <div class="profile__header">
      <div></div>
      <v-avatar
        size="100"
      >
        <v-img
          :src="user.info.avatar" 
        />
      </v-avatar>
      <div></div>
    </div>
    <div class="profile__body">
      <div class="profile__fullname headline font-weight-medium text-center">
        {{ user.info.name }} {{ user.info.surname }}
      </div>
      <div class="profile__role title font-weight-regular text-center">
        {{ user.info.role }}
      </div>
      <div class="profile__created-at body-2 mt-1 text-center">
        Customer since: {{ formatDate(user.createdAt) }}
      </div>
      <div class="profile__last-signed-at body-2 mt-2 text-center">
        Last seen: {{ formatDate(user.info.lastSeenAt) }}
      </div>

    </div>
  </div>
</template>
<script>
import { formatDate } from '@/utils/functions'

export default {
  props: {
    userData: {
      type: Array,
      required: true
    }
  },
  name: 'Profile',
  data: () => ({
    needToBeShow: ['location', 'phone']
  }),
  computed: {
    fields() {
      return Object.keys(this.user.info).filter(key => this.needToBeShow.includes(key)).reduce((acc, key) => {
        acc = {...acc, [key]: this.user.info[key]}
        return acc
      }, {})
    },
    userId() {
      return this.userData[0]
    },
    user() {
      return this.userData[1]
    }
  },
  methods: {
    formatDate(date) {
      return formatDate(date, { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' })
    }
  }
}
</script>
<style lang="scss">
.profile {
  width: 100%;
  background: #fff;
  &__header {
    position: relative;
    height: 128px;
    background: transparent;

    & > div:first-child {
      background: #2d9cd9;
    }

    & > div.v-avatar {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    & > div:not(.v-avatar) {
      height: 64px;
      color: #fff;
    }
  }
}
</style>