<template>
  <div class="timeline">
    <activities 
      class="pa-5"
      :items="activities"
      :types="types"
      v-model="activeType"
      @clickOnItem="onClickOnItem"
    />
    <div
      class="timeline__items px-5"
    >
      <v-subheader class="px-0">Events</v-subheader>
      <v-timeline
        dense
        clipped
        class="timeline__items"
      >
        <v-timeline-item
          v-for="timelineDataItem in calculatedTimelineData"
          :key="`timeline-item-${timelineDataItem.date ? timelineDataItem.date : timelineDataItem[0]}`"
          class="timeline__item mb-4 pb-0"
          :class="{
            'timeline__item--date': timelineDataItem.date
          }"
          :color="!timelineDataItem.date ? types[timelineDataItem[1].type] && types[timelineDataItem[1].type].color || types.etc.color : types.etc.color"
          fill-dot
          small
          :hide-dot="!!timelineDataItem.date"
        >
          <template 
            v-slot:icon
          >
            <v-icon
              dark
              v-if="!timelineDataItem.date"
              x-small
            >
              {{ types[timelineDataItem[1].type] && types[timelineDataItem[1].type].icon || types.etc.icon }}
            </v-icon>
          </template>
          <v-list-item
            dense
            style="min-height: 36px;"
            class="px-0"
          >
            <v-list-item-content
              class="pa-0"
            >
              <template 
                v-if="!timelineDataItem.date"
              >
                <v-list-item-title>
                  <span>{{ timelineDataItem[1].event }}</span>
                  <small class="pl-3">{{ formatDateTime(timelineDataItem[1].createdAt) }}</small>
                </v-list-item-title>
              </template>
              <template v-else>
                <v-list-item-title>
                  <strong class="font-weight-regular" style="font-size: 18px;">{{ formatDate(timelineDataItem.date) }}</strong>
                </v-list-item-title>
              </template>
            </v-list-item-content>
          </v-list-item>
        </v-timeline-item>
      </v-timeline>
    </div>
  </div>
</template>
<script>
import { formatDateTime, formatDate } from '@/utils/functions'

import Activities from './activities'

export default {
  props: {
    timelineData: {
      type: Array,
      required: true
    }
  },
  name: 'Timeline',
  components: {
    Activities
  },
  data: () => ({
    activeType: null,
    types: {
      application: {
        color: 'green',
        icon: 'mdi-dots-grid',
        title: 'Application'
      },
      ecommerce: {
        color: 'deep-purple',
        icon: 'mdi-credit-card',
        title: 'E-commerce'
      },
      email: {
        color: 'indigo',
        icon: 'mdi-email-outline',
        title: 'Email'
      },
      etc: {
        color: 'warning',
        icon: 'mdi-dots-horizontal',
        title: 'Etc'
      }
    }
  }),
  computed: {
    calculatedTimelineData() {
      let result = this.timelineData

      if (this.activeType === null) return result

      const equalByType = ([, data]) => {
        return this.activeType === 'etc' ? !Object.keys(this.types).includes(data.type) : data.type === this.activeType
      }

      return result.filter(item => item.date || equalByType(item))
    },
    activities() {
      const onlyEvents = this.timelineData.filter(timelineItem => !timelineItem.date).map(event => event[1].type)
      const uniqueEvents = [...new Set(onlyEvents)].map(type => ({ id: type }))
      return uniqueEvents
    }
  },
  methods: {
    onClickOnItem(activity) {
      if (activity === null || activity.id === this.activeType) {
        this.activeType = null
        return
      }

      const { id } = activity
      const type = this.types[id] ? id : 'etc'

      this.activeType = type
    },
    formatDate(date) {
      return formatDate(date, { day: 'numeric', month: 'long', year: 'numeric' })
    },
    formatDateTime(date) {
      return formatDateTime(date, { hour: '2-digit', minute: '2-digit'})
    }
  }
}
</script>
<style lang="scss">
.v-timeline {
  padding: 24px 0px 0px;

  &:before {
    display: none;
  }
}
.v-timeline-item {
  &.timeline__item--date {
    .v-timeline-item__divider {
      display: none !important;
    }

    .v-timeline-item__body {
      max-width: 100%;
      padding-left: 36px;
    }
  }
}
</style>