<template>
  <div class="timeline__activities">
    <v-subheader class="px-0">Activities</v-subheader>
    <div class="d-flex justify-center">
      <div
        v-for="(item, index) in items"
        :key="`item-${index}`"
      >
        <v-avatar
          class="mx-2"
          @click="() => onClick(item)"
          style="cursor: pointer;"
          dark
          :style="{
            filter: (types[item.id] && item.id || 'etc') === model ? 'brightness(1.5)' : 'none'
          }"
          :color="types[item.id] && types[item.id].color || 'warning'"
        >
          <v-icon
            v-if="types[item.id] && types[item.id].icon"
            dark
          >
            {{ types[item.id].icon }}
          </v-icon>
          <template v-else>
            <v-icon
              dark
            >
              {{ types.etc.icon }}
            </v-icon>
          </template>
        </v-avatar>
        <small style="width: 100%; display: block; font-size: 10px;" class="text-center pt-2">
          {{ types[item.id] && types[item.id].title || types.etc.title }}
        </small>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      required: true
    },
    types: {
      type: Object,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },
  name: 'TimelineActivities',
  computed: {
    model: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    onClick(item) {
      const hasInTypes = item && Object.keys(this.types).includes(item.id)
      this.$emit('clickOnItem', hasInTypes ? item : { id: 'etc' })
    }
  }
}
</script>